import { SlideContainer, ImageContainer } from './style'


export default function SlidesManipulate(props){
    return(
        <SlideContainer>
            <span>{props.slide.number}</span>
            <ImageContainer selected={props.slide.selected} normallyExcluded={props.slide.normallyExcluded} onClick={() => props.toggleSelected(props.slide.number)}>
                <img alt='slide' src={props.slide.image}></img>
            </ImageContainer>
        </SlideContainer>
    )
}

