import styled from 'styled-components'

export const MainContainer = styled.div`
    margin-right: 0.25em;

    & > div.field {
        margin: 0 !important;
    }

    .error {
        background-color: #fff6f6 !important;
        border-color: #e0b4b4 !important;
        color: #9f3a38 !important;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    div.group-title {
        padding: 10px;
        color: rgba(0,0,0,.6);
        font-weight: 700;
    }

    li.group {
        border-top: 1px solid rgba(34,36,38,.15);
    }

    li.item {
        padding: 10px;
        color: rgba(0,0,0,.5);
    }

    li.item:hover {
        background-color: rgba(0,0,0,.07);
        cursor: pointer;
    }
`

export const ResultsContainer = styled.div`
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-top: 5px;
    border: 1px solid rgba(34,36,38,.15);
    min-width: 300px;
    position: absolute;
    z-index: 999;
    overflow-y: scroll;
    max-height: 40vh;
`

export const OptionContainer = styled.div`
    display: flex;
    justify-content: space-between;
`