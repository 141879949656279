import { Container, FileInfo } from './style'

import { MdCheckCircle, MdError } from 'react-icons/md'
import { Loader }                 from 'semantic-ui-react'


export default function FileList({ files, removeFile }){
    return(
        <Container>
            {files.map(file => (
                <li key={file.id}>
                    <FileInfo>
                        <div>
                            <strong>{file.name}</strong>
                            <span>{file.readableSize}
                                <button onClick={() => removeFile(file.id)}>Remover</button>
                            </span>
                        </div>
                    </FileInfo>
                    <div>
                        {file.inProcess && <Loader active size='small' inline/>} 
                        {file.success && <MdCheckCircle size={24} color='#78d5e5'/>} 
                        {file.error && <MdError size={24} color='#e57878'/>}
                    </div>
                </li>
            ))}
        </Container>
    )
}