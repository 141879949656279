import styled from 'styled-components'

export const ImagePreviewContainer = styled.div`
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: 1fr 5fr;

    input {
        display: none;
    }
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    
    svg:hover{
        transition: 150ms ease-in-out;

        cursor: pointer;
        transform: scale(1.1);
    }
`

export const ImagePreview = styled.div`
    width: 100%;
    max-height: 133px;

    text-align: center;
    border: 0.5px solid #dddddd;
    background-color: rgb(224 225 226);
    border-radius: 4px;
    cursor: pointer;

    transition: 130ms ease-in-out;
    overflow: hidden;

    img{
        ${props => props.type === 'footer' ? 'width: 100%;' : 'max-width: 100%;'}
        height: 100%;
    }

    &:hover{
        box-shadow: 0 .2rem 0.8rem rgba(0,0,0,.15);
        border: 0.5px solid rgb(192 193 194);
    }
`