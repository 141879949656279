import styled from 'styled-components'


export const FooterContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas: "audity button";
  grid-template-columns: auto 30%;
  position: relative;
`

export const IsAudityContainer = styled.div`
  grid-area: "audity";
`

export const ButtonContainer = styled.div`
  grid-area: "button";
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

