import React    from 'react'
import Dropzone from 'react-dropzone'


import { DropContainer, UploadMessage } from './styles'

import { api } from '../../services/api'


export default function UploadFile(props){
    function renderDragMessage(isDragActive, isDragReject, acceptedFiles){
        if(!!acceptedFiles.length && props.endPoint === 'upload/ppt/create') {
            return <UploadMessage>{acceptedFiles[0].name}</UploadMessage>
        }
        if (!isDragActive){
            return <UploadMessage>Arraste arquivos aqui...</UploadMessage>
        }
        if (isDragReject){
            return <UploadMessage type='error'>Arquivo não suportado</UploadMessage>
        }

        return <UploadMessage type='success'>Solte os arquivos aqui</UploadMessage>
    }

    async function uploadFile(file, company) {
        const formData = new FormData()

        formData.append('file', file[0])
        formData.append('company', company)

        props.handlePresentation({ status: 'waiting' })

        const res = await api.post('uploads/ppt/preview', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        props.handlePresentation(res.data)
    }
    
    return (
        <Dropzone onDropAccepted={file => props.endPoint === 'upload/ppt/create' ? uploadFile(file, props.selectedCompany) : props.process(file)}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => (
                <DropContainer
                    {...getRootProps()}
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                >
                    <input {...getInputProps()}/>

                    {renderDragMessage(isDragActive, isDragReject, acceptedFiles)}
                </DropContainer>
            )}
        </Dropzone>
    )
}