import { useEffect, useState } from 'react'
import { ActionsContainer, BodyContainer, HeaderContainer, MainContainer } from './style'
import { Button, Icon, Header, Placeholder } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import TableComponent from './Table'
import SearchComponent from './Search'
import ModalDelete from './ModalDelete'
import ModalAdd from './ModalAdd'
import ModalEdit from './ModalEdit'
import { suridataApi } from '../../services/api'
import FiltersComponent from './Filters'


export default function Credentials(){
    const [credentials, setCredentials]                 = useState([])
    const [companies, setCompanies]                     = useState([])
    const [operators, setOperators]                     = useState([])
    const [brokers, setBrokers]                         = useState([])
    const [filteredCredentials, setFilteredCredentials] = useState([])
    const [loading, setLoading]                         = useState(true)
    const [trashDisabled, setTrashDisabled]             = useState(true)
    const [modalDeleteVisible, setModalDeleteVisible]   = useState(false)
    const [modalAddVisible, setModalAddVisible]         = useState(false)
    const [modalEditVisible, setModalEditVisible]       = useState(false)
    const [editableCredential, setEditableCredential]   = useState('')
    const [filterOpen, setFilterOpen]                   = useState(false)
    const [selectedAmount, setSlectedAmount]            = useState(0)

    function handleCredentials(credentials) {
        const credentialsWithChecked = credentials.map(credential => (
            {...credential, checked: false}
        ))

        setCredentials(credentialsWithChecked)
        setFilteredCredentials(credentialsWithChecked)
    }

    function handleCompanies(companies) {
        const sanitizedCompanies = companies
            .filter(company => company.name && company.operator.name)
            .map(company => ({
                id: company.id,
                name: company.name,
                operator: company.operator.name,
                status: company.status,
            }))

        setCompanies(sanitizedCompanies)
    }

    useEffect(() => {
        Promise.all([
            suridataApi.get('credentials'),
            suridataApi.get('companies'),
            suridataApi.get('operators'), 
            suridataApi.get('brokers')
        ])
            .then((values) => {
                const [
                    credentialsResponse, 
                    companiesResponse, 
                    operatorsResponse, 
                    brokersResponse
                ] = values

                handleCredentials(credentialsResponse.data.data)
                handleCompanies(companiesResponse.data.data)
                setOperators(operatorsResponse.data.data)
                setBrokers(brokersResponse.data.data)
            })
            .catch((error) => {
                if(error.message.includes('401')){
                    toast.error('Você não possui autorização para ter acesso as credenciais!', { autoClose: false })
                }else{
                    toast.error('Não foi possível obter os dados do banco, tente novamente mais tarde.', { autoClose: false })
                }
            })
            .finally(() => setLoading(false))
    }, [])


    useEffect(() => {
        if (filteredCredentials.some(credential => credential.checked === true)) {
            setSlectedAmount(filteredCredentials.filter(cred => cred.checked).length)
            setTrashDisabled(false)
        } else {
            setTrashDisabled(true)
        }
    }, [filteredCredentials])


    function handleCleanSearch() {
        setFilteredCredentials(credentials)
    }


    function handleSearchSelect(company) {
        setFilteredCredentials(credentials.filter(credential => {
            if(credential.company.id === company.id){
                return credential
            }
        }))
    }


    function handleEditClick(credential) {
        setModalEditVisible(true)
        setEditableCredential(credential)
    }


    function refreshCredentials() {
        suridataApi
            .get('credentials')
            .then((res) => handleCredentials(res.data.data))
    }


    return (
        <>
            <MainContainer>
                <HeaderContainer>
                    <div style={{display: 'flex'}}>
                        <SearchComponent
                            options={companies}
                            groupBy='operator'
                            searchBy='name'
                            placeholder='Buscar empresa...'
                            loading={loading}
                            disabled={loading}
                            onSelectOption={handleSearchSelect}
                            onCleanSearch={handleCleanSearch}
                        />
                        <FiltersComponent
                            isOpen={filterOpen}
                            setOpen={setFilterOpen}
                            operators={operators}
                            brokers={brokers}
                            credentials={credentials}
                            loading={loading}
                            setFilteredCredentials={setFilteredCredentials}
                        />
                    </div>
                    <ActionsContainer>
                        {
                            !trashDisabled &&
                            <Header as='h6'>{selectedAmount} item(s) selecionado(s)</Header>
                        }
                        <Button icon disabled={trashDisabled} onClick={() => setModalDeleteVisible(true)}>
                            <Icon name='trash'/>
                        </Button>
                        <Button 
                            icon 
                            primary 
                            disabled={loading}
                            onClick={() => setModalAddVisible(true)}
                        >
                            <Icon name='add'/>
                        </Button>
                    </ActionsContainer>
                </HeaderContainer>
                <BodyContainer>
                    <TableComponent 
                        loading={loading} 
                        filteredCredentials={filteredCredentials}
                        setFilteredCredentials={setFilteredCredentials}
                        handleEditClick={handleEditClick}
                    />
                    {
                        loading ?
                            <Placeholder style={{width: '200px'}}>
                                <Placeholder.Line style={{paddingTop: '20px'}}>

                                </Placeholder.Line>
                            </Placeholder> :
                            <Header as='h6' style={{marginTop: '20px'}}>Total de registros: {filteredCredentials.length}</Header>
                    }
                </BodyContainer>
            </MainContainer>
            {
                modalAddVisible &&
                <ModalAdd 
                    modalVisible={modalAddVisible} 
                    setModalVisible={setModalAddVisible} 
                    companies={companies}
                    refreshCredentials={refreshCredentials}

                />
            }
            {
                modalDeleteVisible &&
                <ModalDelete 
                    modalVisible={modalDeleteVisible} 
                    setModalVisible={setModalDeleteVisible} 
                    filteredCredentials={filteredCredentials}
                    setFilteredCredentials={setFilteredCredentials}
                />
            }
            {
                modalEditVisible &&
                <ModalEdit 
                    modalVisible={modalEditVisible} 
                    setModalVisible={setModalEditVisible} 
                    companies={companies}
                    credential={editableCredential}
                    refreshCredentials={refreshCredentials}
                />
            }
        </>
    )
}
