import { Title } from './style'
import { Icon } from 'semantic-ui-react'


export default function AccordionTitle({children, onClick, isActive}) {
    return (
        <Title onClick={onClick}>
            <strong>{children}</strong>
            <Icon name={isActive ? 'angle down' : 'angle right'}/>
        </Title>
    )
}