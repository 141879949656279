import { useState } from 'react'
import { Icon, Button, Divider, Dropdown, Checkbox, Label } from 'semantic-ui-react'
import { 
    ContentContainer, 
    BodyContainer, 
    HeaderContainer, 
    MainContainer, 
    StatusContainer,
    FooterContainer,
    AppliedFiltersContainer,
} from './style'
import { Accordion } from '../Accordion'


export default function FiltersComponent({
    operators, 
    brokers, 
    isOpen, 
    setOpen, 
    loading,
    credentials,
    setFilteredCredentials
}){
    const [activeIndex, setActiveIndex] = useState(-1)
    const [appliedFiltersAmount, setAppliedFiltersAmount] = useState(0)
    const [appliedFilters, setAppliedFilters] = useState({
        status: {text: 'Todos', value: -1},
        operators: [],
        brokers: [],
        operations: []
    })

    const filters = {
        operators: operators,
        brokers: brokers,
        status: [
            {
                text: 'Todos',
                value: -1,
            },
            {
                text: 'Ativa',
                value: 1,
            },
            {
                text: 'Inativa',
                value: 0,
            }
        ],
        operations: [
            {
                text: 'Prêmio',
                value: 'premio',
            },
            {
                text: 'Sinistro',
                value: 'sinistro',
            },
            {
                text: 'Sinistralidade',
                value: 'sinistralidade',
            },
            {
                text: 'Senhas',
                value: 'senhas',
            }
        ]
    }

    function handleAccordion(index) {
        activeIndex === index ? setActiveIndex(-1) : setActiveIndex(index)
    }

    function countFilters() {
        const filters = Object.keys(appliedFilters).reduce((acc, key) => {
            if(key === 'status' && appliedFilters[key].value !== -1){
                acc.push(appliedFilters[key])
            } 

            if(appliedFilters[key].length) {
                acc.push(...appliedFilters[key])
            }

            return acc
        }, [])

        setAppliedFiltersAmount(filters.length)
    }

    function handleFilterStatus(statusValue) {
        setAppliedFilters(prev => ({
            ...prev, status: filters.status.find(e => e.value === statusValue)
        }))
    }

    function handleFilterOperations(operation, isActive) {
        setAppliedFilters(prev => ({
            ...prev, 
            operations: isActive ? [...prev.operations, operation] : prev.operations.filter(e => e.value !== operation.value)
        }))
    }

    function handleFilterOperators(operator, isActive) {
        setAppliedFilters(prev => ({
            ...prev, 
            operators: isActive ? [...prev.operators, operator] : prev.operators.filter(e => e.id !== operator.id)
        }))
    }

    function handleFilterBrokers(broker, isActive) {
        setAppliedFilters(prev => ({
            ...prev, 
            brokers: isActive ? [...prev.brokers, broker] : prev.brokers.filter(e => e.id !== broker.id)
        }))
    }

    function applyFilters(){
        const filteredOperatorIds = appliedFilters.operators.map(e => e.id)
        const filteredBrokerIds = appliedFilters.brokers.map(e => e.id)
        const filteredOperationValues = appliedFilters.operations.map(e => e.value)

        return credentials.filter(cred => 
            (filteredOperatorIds.length > 0 ? filteredOperatorIds.includes(cred.operator.id) : true) &&
            (filteredBrokerIds.length > 0 ? filteredBrokerIds.includes(cred.broker.id) : true) &&
            (appliedFilters.status.value >= 0 ? cred.status === appliedFilters.status.value : true) &&
            (filteredOperationValues.length > 0 ?filteredOperationValues.some(item => cred.operations.includes(item)) : true)
        )
    }

    function handleConfirm() {
        const filteredCredentials = applyFilters()

        setFilteredCredentials(filteredCredentials)
        countFilters()
        setOpen(false)
    }

    function cleanFilters() {
        setFilteredCredentials(credentials)
        setAppliedFiltersAmount(0)
        setAppliedFilters({
            status: {text: 'Todos', value: -1},
            operators: [],
            brokers: [],
            operations: []
        })
    }

    return (
        <>
            <MainContainer>
                <Button icon onClick={() => setOpen(prev => !prev)} disabled={loading}>
                    <Icon name='filter'/>
                </Button>
                <ContentContainer hidden={!isOpen}>
                    <HeaderContainer>
                        <strong>Filtros</strong>
                        <Button 
                            icon 
                            basic 
                            circular 
                            style={{ boxShadow: 'none' }} 
                            onClick={() => setOpen(false)}
                        >
                            <Icon name='delete'/>
                        </Button>
                    </HeaderContainer>
                    <Divider/>
                    <BodyContainer>
                        <StatusContainer>
                            <label>Status</label>
                            <Dropdown 
                                selection 
                                options={filters.status} 
                                defaultValue={-1}
                                onChange={(e, data) => handleFilterStatus(data.value)}
                            />
                        </StatusContainer>
                        <Accordion.Root>
                            <Accordion.Title 
                                isActive={activeIndex === 1} 
                                onClick={() => handleAccordion(1)}
                            >Operadoras</Accordion.Title>
                            <Accordion.Content isActive={activeIndex === 1}>
                                {
                                    filters.operators.map((operator) => (
                                        <Checkbox 
                                            checked={appliedFilters.operators.some(e => operator.id === e.id)}
                                            label={operator.name} 
                                            key={operator.id}
                                            onChange={(e, data) => handleFilterOperators(operator, data.checked)}
                                        />
                                    ))
                                }
                            </Accordion.Content>
                            <Accordion.Title 
                                isActive={activeIndex === 2} 
                                onClick={() => handleAccordion(2)}
                            >Corretoras</Accordion.Title>
                            <Accordion.Content isActive={activeIndex === 2}>
                                {
                                    filters.brokers.map((broker) => (
                                        <Checkbox 
                                            checked={appliedFilters.brokers.some(e => broker.id === e.id)}
                                            label={broker.name} 
                                            key={broker.id}
                                            onChange={(e, data) => handleFilterBrokers(broker, data.checked)}
                                        />
                                    ))
                                }
                            </Accordion.Content>
                            <Accordion.Title 
                                isActive={activeIndex === 0} 
                                onClick={() => handleAccordion(0)}
                            >Operações</Accordion.Title>
                            <Accordion.Content isActive={activeIndex === 0}>
                                {
                                    filters.operations.map((operation, index) => (
                                        <Checkbox 
                                            checked={appliedFilters.operations.some(e => operation.value === e.value)}
                                            label={operation.text} 
                                            key={index}
                                            onChange={(e, data) => handleFilterOperations(operation, data.checked)}
                                        />
                                    ))
                                }
                            </Accordion.Content>
                        </Accordion.Root>
                    </BodyContainer>
                    <FooterContainer>
                        <Button size='tiny' onClick={cleanFilters}>Limpar</Button>
                        <Button size='tiny' color='primary' onClick={handleConfirm}>Confirmar</Button>
                    </FooterContainer>
                </ContentContainer>
            </MainContainer>
            <AppliedFiltersContainer>
                {
                    appliedFiltersAmount > 0 &&
                    <Label color='blue'>
                        {appliedFiltersAmount} Filtros
                        <Icon name='delete' onClick={cleanFilters} />
                    </Label>
                }
            </AppliedFiltersContainer>
        </>
    )
}

