import styled from 'styled-components'

export const MainContainer = styled.div`
    margin-top: 20px;
    min-width: 1000px;
    max-height: 500px;
    overflow-y: auto;

    thead {
        position: sticky;
        top: 0;
        z-index: 9;
        border-top: 0;
    }

    table {
        border: 0;
    }
`
