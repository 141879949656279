import styled from 'styled-components'

export const MainContainer = styled.div`
    .dropdown {
        margin: 0 0.50em;
    }
    position: relative;
`

export const ContentContainer = styled.div`
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-top: 5px;
    border: 1px solid rgba(34,36,38,.15);
    width: 300px;
    position: absolute;
    z-index: 99;
`

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 0 10px;
`

export const StatusContainer = styled.div`
    padding: 0 10px 10px;
    display: flex;
    flex-direction: column;

    .dropdown {
        margin: 0;
        max-width: 100px;
    }
`

export const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;

    strong {
        margin-bottom: 10px; 
    }
`


export const FooterContainer = styled.div`
    padding: 0 10px 10px 10px;
    display: flex;
    justify-content: end;
`


export const AppliedFiltersContainer = styled.div`
    display: flex;
    align-items: center;
`