import { Label } from 'semantic-ui-react'


export default function SlidesNumbers(props){
    return(
        <div>
            <Label.Group>
                {props.presentation.map(slide => {
                    if(slide.selected) return <Label key={slide.number}>{slide.number}</Label>
                    return null
                })}
            </Label.Group>
        </div>
    )
}

