import { useState } from 'react'
import { Input, Icon, Label } from 'semantic-ui-react'
import { MainContainer, OptionContainer, ResultsContainer } from './style'
import _ from 'lodash'


export default function SearchComponent({
    options = [], 
    groupBy = '', 
    searchBy = '', 
    onSelectOption = () => {},
    onCleanSearch = () => {},
    value = '',
    ...props
}){
    const [searchValue, setSearchValue] = useState(value)
    const [resultsVisible, setResultsVisible] = useState(false)

    const filteredOptions = options.filter(option => {
        return option[searchBy].toLowerCase().includes(searchValue.toLowerCase())
    })

    const groupedOptions = _.groupBy(filteredOptions, groupBy)

    const companyStatus = {
        0: {
            color: 'green',
            label: 'Ativa'
        },
        1: {
            color: 'red',
            label: 'Inativa'
        },
        2: {
            color: 'blue',
            label: 'Implantação'
        },
        3: {
            color: 'brown',
            label: 'Histórico'
        },
    }

    function handleSelectOption(option) {
        onSelectOption(option)
        setSearchValue(option[searchBy])
        setResultsVisible(false)
    }

    function handleInputFocus() {
        setSearchValue('')
        setResultsVisible(true)
    }

    function handleCleanSearch() {
        onCleanSearch()
        setSearchValue('')
    }

    return (
        <MainContainer>
            <Input
                iconPosition='left'
                icon={
                    searchValue ? 
                        <Icon name='delete' link onClick={handleCleanSearch}/> : 
                        <Icon name='search'/>
                }
                placeholder={props.placeholder || 'Buscar...'}
                value={searchValue}
                onChange={event => setSearchValue(event.target.value)}
                onFocus={handleInputFocus}
                onBlur={() => setResultsVisible(false)}
                style={{minWidth: '280px'}}
                {...props}
            />
            <ResultsContainer hidden={!resultsVisible}>
                <div>
                    {
                        filteredOptions.length ?
                            <ul>
                                {
                                    Object.keys(groupedOptions).map((group, i) => (
                                        <li key={i} className='group'>
                                            <div className='group-title'>
                                                <p>{group}</p>
                                            </div>
                                            <ul>
                                                {
                                                    groupedOptions[group].map((option, j) => (
                                                        <li 
                                                            key={j} 
                                                            className='item' 
                                                            onMouseDown={e => handleSelectOption(option)}
                                                        >
                                                            <OptionContainer>
                                                                {option[searchBy]}
                                                                {
                                                                    option.status !== 0 &&
                                                                    <Label 
                                                                        color={companyStatus[option.status].color}
                                                                        size='tiny'
                                                                    >
                                                                        {companyStatus[option.status].label}
                                                                    </Label>
                                                                }
                                                            </OptionContainer>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </li>
                                    ))
                                }
                            </ul> : 
                            <div className='group-title'>
                                <p>Nenhum resultado encontrado.</p>
                            </div>
                    }
                </div>
            </ResultsContainer>
        </MainContainer>
    )
}

