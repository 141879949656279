import styled from 'styled-components'


export const Container = styled.div`
    margin: 1rem 0;
`

export const Title = styled.div`
    padding: 12px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(34,36,38,.15);
    cursor: pointer;

    strong {
        margin: 0;
    }
`

export const Content = styled.div`
    padding: 10px;
    max-height: 250px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    display: ${props => props.active ? 'visible' : 'none'};

    label {
        margin: 0;
    }

    .checkbox {
        margin-bottom: 5px;
    }
`
