import styled from 'styled-components'


export const Title = styled.span`
`

export const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;

    span {
        margin: 5px 0;
    }
`

export const ErrorMessage = styled.span`
    display: flex;
    flex-direction: column;
`