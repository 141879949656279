import { Button, Modal, Icon, Header } from 'semantic-ui-react'
import { suridataApi } from '../../../services/api'
import { toast } from 'react-toastify'


export default function ModalDelete({ modalVisible, setModalVisible, filteredCredentials, setFilteredCredentials }){
    function handleConfirm() {
        const credentialsToRemove = filteredCredentials.filter(e => e.checked)

        setModalVisible(false)
        toast.promise(
            Promise.all(
                credentialsToRemove.map(
                    (e) => suridataApi.delete(`credentials/${e.id}`)
                )
            ),
            {
                pending: 'Removendo credenciais',
                success: 'Credencial removida com sucesso!',
                error: 'Ocorreu um erro ao remover a credencial'
            }
        )
        setFilteredCredentials((creds) => creds.filter((cred) => credentialsToRemove.includes(cred.id)))
    }

    return (
        <Modal
            open={modalVisible}
            dimmer='blurring'
            style={{
                position: 'relative',
                height: '200px'
            }}
        >
            <Header>
                <Icon name='warning circle' color='yellow'/>
                Atenção!
            </Header>
            <Modal.Content>
                Tem certeza que deseja excluir <strong>{filteredCredentials.filter(e => e.checked).length}</strong> credenciais?
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setModalVisible(false)}>Cancelar</Button>
                <Button negative onClick={handleConfirm}>Excluir</Button>
            </Modal.Actions>
        </Modal>
    )
}

