import { Table, Checkbox, Label, Placeholder, Button, Icon } from 'semantic-ui-react'
import { ClipboardContainer, MainContainer } from './style'
import { uniqueId } from 'lodash'


function TablePlaceholder() {
    return (
        <Table.Body>
            {
                Array.from({ length: 3 }).map((i) => (
                    <Table.Row key={uniqueId()}>
                        {
                            Array.from({ length: 9 }).map((j) => (
                                <Table.Cell key={uniqueId()}>
                                    <Placeholder>
                                        <Placeholder.Line/>
                                    </Placeholder>
                                </Table.Cell>
                            ))
                        }
                    </Table.Row>
                ))
            }
        </Table.Body>
    )
}

function NoData() {
    return (
        <Table.Body>
            <Table.Row>
                <Table.Cell colSpan='8'>
                    <h3 style={{color: 'rgba(0,0,0,.5)'}}>Nenhuma credencial encontrada</h3>
                </Table.Cell>
            </Table.Row>
        </Table.Body>
    )
}

export default function TableComponent({ filteredCredentials, setFilteredCredentials, loading, handleEditClick }){
    function handleCheck(credentialId) {
        setFilteredCredentials(prev => prev.map(credential => {
            return credential.id === credentialId ? {...credential, checked: !credential.checked} : credential
        }))
    }

    function handleCheckAll(checked){
        setFilteredCredentials(prev => prev.map(credential => (
            {...credential, checked}
        )))
    }

    return(
        <MainContainer>
            <Table textAlign='center' selectable={!loading}> 
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Checkbox 
                                onClick={(e, data) => handleCheckAll(data.checked)} 
                                disabled={loading}
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>Operadora</Table.HeaderCell>
                        <Table.HeaderCell>Corretora</Table.HeaderCell>
                        <Table.HeaderCell>Empresa</Table.HeaderCell>
                        <Table.HeaderCell>Usuário</Table.HeaderCell>
                        <Table.HeaderCell>Senha</Table.HeaderCell>
                        <Table.HeaderCell>Complemento</Table.HeaderCell>
                        <Table.HeaderCell>Operações</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {
                    loading ? 
                        <TablePlaceholder/> : 
                        !filteredCredentials.length ?
                            <NoData/> :
                            <Table.Body>
                                {
                                    filteredCredentials.map((credential, i) => (
                                        <Table.Row key={i}>
                                            <Table.Cell verticalAlign='middle'>
                                                <Checkbox checked={credential.checked} onChange={e => handleCheck(credential.id)}/>
                                            </Table.Cell>
                                            <Table.Cell verticalAlign='middle'>{credential.operator.name}</Table.Cell>
                                            <Table.Cell verticalAlign='middle'>{credential.broker.name}</Table.Cell>
                                            <Table.Cell verticalAlign='middle'>{credential.company.name}</Table.Cell>
                                            <Table.Cell verticalAlign='middle'>{credential.username}</Table.Cell>
                                            <Table.Cell verticalAlign='middle'>{credential.password}</Table.Cell>
                                            <Table.Cell verticalAlign='middle'>{credential.complement}</Table.Cell>
                                            <Table.Cell verticalAlign='middle'>
                                                {
                                                    credential.operations.map((operation, i) => (
                                                        <Label key={i}>{operation}</Label>
                                                    ))
                                                }
                                            </Table.Cell>
                                            <Table.Cell verticalAlign='middle'>
                                                <Label color={credential.status === 1 ? 'green' : 'red'}>
                                                    {credential.status === 1 ? 'Ativa' : 'Inativa'}
                                                </Label>
                                            </Table.Cell>
                                            <Table.Cell verticalAlign='middle'>
                                                <Button 
                                                    circular 
                                                    size='tiny' 
                                                    basic 
                                                    icon='edit' 
                                                    onClick={e => handleEditClick(credential)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                }
            </Table>
        </MainContainer>
    )
}

