import { createGlobalStyle } from 'styled-components'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'semantic-ui-css/semantic.min.css'
import 'react-circular-progressbar/dist/styles.css'

import background from '../assets/bg.png'

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    body{
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 14px;
        background-image: url("${background}");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 45%;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
    }

    html, body, #root{
        height: 100%;
        width: 100%;
    }
`