import { Container } from './style'

import { Checkbox, Header } from 'semantic-ui-react'


export default function IsAudity({ handleAudity }){
    return(
        <Container>
            <Header as='h6'>Auditoria?</Header>
            <Checkbox toggle onChange={handleAudity}/>
        </Container>
    )
}