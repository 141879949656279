import { useState } from 'react'
import { uniqueId } from 'lodash'
import filesize     from 'filesize'
import {
    Button,
    Divider,
} from 'semantic-ui-react'

import { toast }        from 'react-toastify'
import { api, baseURL } from '../../services/api'
import UploadFile       from '../UploadFile'
import FileList         from '../FileList'
import IsAudity         from '../IsAudity'
import { 
    FooterContainer, 
    IsAudityContainer,
    ButtonContainer 
} from './styles'


export default function ProcessFile(props){
    const [urlToDownload, setUrlToDownload]       = useState('')
    const [countDownload, setCountDownload]       = useState(0)
    const [uploadedFiles, setUploadedFiles]       = useState([])
    const [PPTProcessStatus, setPPTProcessStatus] = useState(false)
    
    const [buttonDisabled, setButtonDisabled]     = useState(true)
    const [buttonDownload, setButtonDownload]     = useState(false)
    const [buttonLoading, setButtonLoading]       = useState(false)
    const [isAudity, setIsAudity]                 = useState(false)


    function addFilesToUpload(files) {
        const uploadedFiles = files.map(file => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            inProcess: false,
            success: false,
            error: false,
            errorDescription: ''
        }))

        setUploadedFiles(uploadedFiles)

        if(uploadedFiles.length){
            setButtonDisabled(false)
            setButtonDownload(false)
        }
    }

    function removeFile(fileId){
        const newFiles = []

        uploadedFiles.forEach((e, i) => {
            fileId !== e.id && newFiles.push(e)
        })

        setUploadedFiles(newFiles)
    }

    async function downloadFiles() {
        setUrlToDownload(`${baseURL}/ppt/download?c=${countDownload}`)
        setCountDownload((old) => old + 1)
    }

    async function uploadAllFiles(){
        setButtonDisabled(true)
        setButtonLoading(true)

        const formData = new FormData()
        for (const file of uploadedFiles) {
            formData.append('file', file.file)
        }

        const PPTuploadedResp = await api.post('/ppt/store', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })

        if(!PPTuploadedResp.data.uploaded){
            toast.error('Ocorreu um erro ao subir os PPTs para o servidor.')
            setButtonLoading(false)
        }

        const processResp = await api.post('/ppt/process', {isAudity})

        setButtonLoading(false)

        if(!processResp.data.status){
            toast.error(`${processResp.data.statusMessage} - ${processResp.data.companyName}`)
            setPPTProcessStatus(false)
        }else{
            setPPTProcessStatus(true)
            setButtonDownload(true)
            setButtonDisabled(false)
        }

        setUploadedFiles([])
    }

    async function handleAudity(element, {checked}){
        setIsAudity(checked)
    }

    return(
        <>
            <UploadFile process={addFilesToUpload}/>
            { !!uploadedFiles.length && <FileList files={uploadedFiles} removeFile={removeFile}/>}
            <Divider></Divider>
            <FooterContainer>
                <IsAudityContainer>
                    <IsAudity handleAudity={handleAudity}/>
                </IsAudityContainer>
                <ButtonContainer>
                    { 
                        buttonDownload
                            ?
                            <Button 
                                color='green' 
                                onClick={() => downloadFiles()}
                            >Baixar</Button> 
                            :
                            <Button 
                                primary 
                                disabled={buttonDisabled}
                                loading={buttonLoading} 
                                onClick={() => uploadAllFiles()}
                            >Enviar</Button>
                    }
                </ButtonContainer>
            </FooterContainer>
            {urlToDownload && <iframe src={urlToDownload} style={{display: 'none'}} title='download'></iframe>}
        </>
    )
}