export function convertArrayToString(array){
    return array.join(', ')
}

export function convertStringToArray(stringData) {
    stringData = stringData.split(',')
    stringData = stringData.map((e) => {
        return parseInt(e, 10)
    })

    return stringData
}

export const imageToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
})