import styled from 'styled-components'


export const MainContainer = styled.div`
    width: 100%;
    position: relative;
`

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const BodyContainer = styled.div`
    width: 100%;
`

export const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .ui.header{
        margin: 0;
        margin-right: 10px;
    }
`




