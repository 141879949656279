import { useRef, useState, useEffect } from 'react'
import { Popup } from 'semantic-ui-react'
import { MdOpenInNew } from 'react-icons/md'

import { ImagePreview, ImagePreviewContainer, Header } from './style'


export default function InputFileComponent(props){
    const [preview, setPreview] = useState(null)
    const inputFileRef          = useRef(null)

    const translatedType = {
        'Capa': 'cover',
        'Contracapa': 'backCover',
        'Rodapé': 'footer',
        'Cabeçalho': 'header'
    }

    useEffect(() => {
        setPreview(props.image)
    }, [props.image])

    function handleClick() {
        inputFileRef.current.click()
    }

    function handleChange(e) {
        const file = e.target.files[0]
        if(file){
            e.target.value = null
            props.handleUploadFile(file, translatedType[props.type])
            setPreview(URL.createObjectURL(file))
        }
    }

    return(
        <ImagePreviewContainer>
            <Header>
                <label className='mb-2 font-weight-bold'>{props.type}</label>
                <Popup content='Visualizar' position='right center' trigger={
                    <MdOpenInNew size='20px' onClick={() => preview && window.open(preview, '_blank')}/>
                }/>
            </Header>
            <Popup content='Escolher arquivo' position='top center' trigger={
                <ImagePreview onClick={handleClick} type={translatedType[props.type]}>
                    {preview && <img alt='slide' src={preview}></img>}
                </ImagePreview>
            } /> 
            <input
                accept="image/*"
                type="file"
                ref={inputFileRef}
                onChange={handleChange}
            />
        </ImagePreviewContainer>
    )
}

