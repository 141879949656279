import styled, { css } from 'styled-components'

const slideSelected = css`
    filter: brightness(50%);
`

const normallyExcluded = css`
    filter: sepia(60%) brightness(85%);
    border: 1px solid #f5d782;
`

export const ImageContainer = styled.div`
    cursor: pointer;

    border: 1px solid #DDD;
    border-radius: 3px;
    width: 100%;

    :hover{
        border: 1px solid #a6a9b6;
    }

    ${props => props.normallyExcluded && normallyExcluded}
    ${props => props.selected && slideSelected}

    transition: 80ms ease-in-out;

    img{
        height: 100%;
        width: 100%;
        transition: 40ms ease-in-out;
    }
`

export const SlideContainer = styled.li`
    display: flex;

    height: 100px;
    width: 100%;
    margin-bottom: 5px;
    
    span{
        text-align: center;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        color: #a6a9b6;
    }
`