import { useEffect, useState } from 'react'
import { Table, Button } from 'semantic-ui-react'

import { gcsAutomateApi } from '../../services/api'
import { ErrorMessage, SummaryContainer } from './style'

export default function Others() {
    const [actions, setActions] = useState([
        {
            title: 'Portal',
            summary: 'Sobe os dados que estão no Storage para o Portal e movimenta os cards no Trello.',
            running: false,
            status: null,
            endpoint: '/start',
            error: {
                message: '',
                where: '',
            },
        },
        {
            title: 'Senhas',
            summary: 'Sobe os dados de senhas que estão no storage para o BigQuery.',
            running: false,
            status: null,
            endpoint: '/senha',
            error: {
                message: '',
                where: '',
            },
        },
        {
            title: 'TUSS',
            summary: 'Sobe os dados de TUSS que estão no storage para o BigQuery.',
            running: false,
            status: null,
            endpoint: '/tuss',
            error: {
                message: '',
                where: '',
            },
        },
        {
            title: 'Utilizacão Hospitais',
            summary: 'Sobe os dados da tabela Utilização Hospitais que estão no storage para o BigQuery.',
            running: false,
            status: null,
            endpoint: '/utilizacao_hospitais',
            error: {
                message: '',
                where: '',
            },
        },
    ])

    function toggleRunning(actionTitle) {
        setActions((prev) =>
            prev.map((action) => {
                if (action.title.toLowerCase() === actionTitle.toLowerCase()) {
                    return { ...action, running: !action.running }
                }

                return action
            })
        )
    }

    async function handleClickPortal(element, endpoint) {
        const currentAction = element.currentTarget.dataset.action

        setActions((prev) =>
            prev.map((e) => {
                if (e.title === currentAction) {
                    return {
                        ...e,
                        status: null,
                    }
                }

                return e
            })
        )

        toggleRunning(currentAction)

        const response = await gcsAutomateApi.post(endpoint)
        const { status, message, where } = response.data

        setActions((prev) =>
            prev.map((e) => {
                if (e.title === currentAction) {
                    return {
                        ...e,
                        error: {
                            message,
                            where,
                        },
                        status,
                    }
                }
                return e
            })
        )

        toggleRunning(currentAction)
    }

    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell aligned center>
            Título
                    </Table.HeaderCell>
                    <Table.HeaderCell aligned center>
            Resumo
                    </Table.HeaderCell>
                    <Table.HeaderCell aligned center>
            Ação
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {actions.map((action, index) => (
                    <Table.Row
                        key={index}
                        negative={action.status === 'error'}
                        positive={action.status === 'success'}
                        style={{ transition: '400ms ease' }}
                    >
                        <Table.Cell collapsing>
                            <label className="mb-2 font-weight-bold">{action.title}</label>
                        </Table.Cell>
                        <Table.Cell>
                            <SummaryContainer>
                                <span>{action.summary}</span>
                                {action.status === 'error' && (
                                    <ErrorMessage>
                                        <span>
                                            <strong>ERRO: </strong>
                                            {action.error.message}
                                        </span>
                                        <span>
                                            <strong>Arquivo: </strong>
                                            {action.error.where}
                                        </span>
                                    </ErrorMessage>
                                )}
                            </SummaryContainer>
                        </Table.Cell>
                        <Table.Cell collapsing textAlign="right">
                            <Button
                                data-action={action.title}
                                positive={action.status === 'success'}
                                negative={action.status === 'error'}
                                onClick={e => handleClickPortal(e, action.endpoint)}
                                icon={action.status === 'error' ? 'redo' : 'play'}
                                primary
                                size="small"
                                loading={action.running}
                                disabled={action.running}
                            />
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
}
