import styled from 'styled-components'


export const HeadContentContainer = styled.div`
    width: 100%;
    max-height: 200px;

    display: grid;
    grid-template-columns: 2fr 1fr;

    div{
        padding: 0 10px;
        text-align: center;
    }
`

export const Footer = styled.div`
    text-align: end;
`

export const CompanyDetailsContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
`

export const CompanyManipulationContainer = styled.div`
    min-height: 500px;
    width: 100%;
    padding: 10px 0;

    display: grid;
    grid-template-columns: auto 2fr;
    grid-template-rows: auto;
`

export const CompanyEditContainer = styled.div`
    border: 0.5px solid #DDD;
    border-radius: 4px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;

    & > div > div {
        width: 300px;
        padding: 10px;
    }
`

export const CompanyEditContentTop = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
`

export const CompanyEditContentBottom = styled.div`
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-gap: 5px;
`


export const CalcContainer = styled.div`
    display: flex;
    flex-direction: column;

    div {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
    }
`

export const MainContainer = styled.div`
    width: 100%;
`

export const PresentationContainer = styled.div`
    height: 530px;
    width: 250px;
    margin-right: 10px;
    overflow-y: scroll;
    border: 0.5px solid #DDD;
    border-radius: 4px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`


export const CheckListContainer = styled.div`
    padding: 10px;
    border-radius: 3px; 
    border: 1px solid #DDD;
    margin: 5px 0;

    label{
        margin: 0;
    }
`
