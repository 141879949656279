import { Tabs, Tab } from 'react-bootstrap'

import CreateCompany     from '../CreateCompany/index'
import ProcessFile       from '../ProcessFile/index'
import Others            from '../Others/index'

import { Container } from './styles'
import Credentials from '../Credentials'

export default function Navigation(){
    return (
        <Tabs defaultActiveKey="process" mountOnEnter={true}>
            <Tab eventKey="process" title="Processar">
                <Container>
                    <ProcessFile/>
                </Container>
            </Tab>
            <Tab eventKey="create" title="Criar">
                <Container>
                    <CreateCompany/>
                </Container>
            </Tab>
            <Tab eventKey="credentials" title="Credenciais">
                <Container>
                    <Credentials/>
                </Container>
            </Tab>
            <Tab eventKey="others" title="Outros">
                <Container>
                    <Others/>
                </Container>
            </Tab>
        </Tabs>
    )
}
