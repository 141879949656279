import { Button, Modal, Form, Dropdown, Icon, Checkbox } from 'semantic-ui-react'
import { useState } from 'react'
import { toast } from 'react-toastify'

import SearchComponent from '../Search'
import { suridataApi } from '../../../services/api'



export default function ModalEdit({ modalVisible, setModalVisible, companies, credential, refreshCredentials }){
    const [formData,  setFormData] = useState({
        company_id: credential.company.id,
        operations: credential.operations,
        username: credential.username,
        password: credential.password,
        complement: credential.complement,
        status: credential.status,
    })
    const [error, setError] = useState({
        company_id: false,
        operations: false,
        username: false,
        password: false,
        complement: false,
    })

    const operations = [
        'premio', 
        'sinistro', 
        'sinistralidade', 
        'senhas'
    ].map(e => ({
        key: e,
        text: e.charAt(0).toUpperCase() + e.slice(1),
        value: e
    }))

    function handleError() {
        Object.keys(formData).map(key => {
            if(!formData[key]){
                setError(prev => ({...prev, [key]: true}))
            }
        })
    }

    function handleChangeEachField(field, value) {
        setError(prev => ({...prev, [field]: false}))
        setFormData(prev => ({...prev, [field]: value}))
    }


    function handleConfirm() {
        handleError()

        toast.promise(
            suridataApi.put(`credentials/${credential.id}`, formData),
            {
                pending: 'Atualizando credencial...',
                success: 'Credencial atualizada!',
                error: 'Ocorreu um erro ao atualizar a credencial'
            }
        )
        setModalVisible(false)
    }

    return (
        <Modal
            open={modalVisible}
            dimmer='blurring'
            style={{position: 'relative', height: 'auto', maxWidth: '500px'}}
            as={Form}
            onSubmit={handleConfirm}
        >
            <Modal.Header>
                <Icon name='info circle' color='blue'/>
                #{credential.id}
            </Modal.Header>
            <Modal.Content >
                <Form.Field error={error.company_id} width={10}>
                    <label>Empresa</label>
                    <SearchComponent
                        options={companies}
                        groupBy='operator'
                        searchBy='name'
                        as={Form.Field}
                        value={credential.company.name}
                        onSelectOption={e => handleChangeEachField('company_id', e.id)}
                    />
                </Form.Field>
                <Form.Field error={error.operations}>
                    <label>Operações</label>
                    <Dropdown
                        placeholder='Selecione...'
                        fluid
                        multiple
                        search
                        selection
                        options={operations}
                        value={formData.operations}
                        onChange={(e, data) => handleChangeEachField('operations', data.value)}
                    />
                </Form.Field>
                <Form.Field error={error.username} width={12}>
                    <label>Usuário</label>
                    <Form.Input 
                        name="username" 
                        placeholder='Usuário'
                        value={formData.username}
                        onChange={e => handleChangeEachField('username', e.target.value)}
                    />
                </Form.Field>
                <Form.Field error={error.password} width={8}>
                    <label>Senha</label>
                    <Form.Input 
                        name="password" 
                        placeholder='Senha'
                        value={formData.password}
                        onChange={e => handleChangeEachField('password', e.target.value)}
                    />
                </Form.Field>
                <Form.Group>
                    <Form.Field error={error.complement} width={6}>
                        <label>Complemento</label>
                        <Form.Input 
                            name="complement" 
                            placeholder='Complemento'
                            value={formData.complement}
                            onChange={e => handleChangeEachField('complement', e.target.value)}
                        />
                    </Form.Field>
                    <Form.Field width={6}>
                        <label>Status</label>
                        <Checkbox 
                            toggle 
                            checked={formData.status}
                            onChange={(e, data) => setFormData(prev => ({...prev, status: data.checked ? 1 : 0}))}
                        />
                    </Form.Field>
                </Form.Group>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setModalVisible(false)}>Cancelar</Button>
                <Button positive>Confirmar</Button>
            </Modal.Actions>
        </Modal>
    )
}

