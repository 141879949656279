import styled from 'styled-components'

export const MainContainer = styled.div`
    width: 100%;
    height: 100%;
`

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px 0;
`

export const Content = styled.div`
    margin: auto;
    min-width: 500px;
    background-color: #FFF;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
`

export const HeaderContent = styled.div`
    width: 100%;
    margin-bottom: 30px;
`

export const HeaderLabelsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const Title = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #393e46;
    margin: 0;
`