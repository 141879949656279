import { Modal } from 'react-bootstrap'
import { Button, List, Label } from 'semantic-ui-react'

export default function ReleaseNotes({
    appVersion, 
    bugsFixes, 
    improvements, 
    features,
    ...props
}) {
    let infos = {
        features: features || [],
        bugsFixes: bugsFixes || [],
        improvements: improvements || [],
    }

    return (
        <Modal
            {...props}
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {appVersion}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!!infos.features.length && (
                    <>
                        <Label color="green">Novo</Label>
                        {infos.features.map((e, i) => {
                            return (
                                <List as="ul" key={i}>
                                    <List.Item as="li">{e}</List.Item>
                                </List>
                            )
                        })}
                    </>
                )}
                {!!infos.improvements.length && (
                    <>
                        <Label color="blue">Melhorado</Label>
                        {infos.improvements.map((e, i) => {
                            return (
                                <List as="ul" key={i}>
                                    <List.Item as="li">{e}</List.Item>
                                </List>
                            )
                        })}
                    </>
                )}
                {!!infos.bugsFixes.length && (
                    <>
                        <Label color="orange">Corrigido</Label>
                        {infos.bugsFixes.map((e, i) => {
                            return (
                                <List as="ul" key={i}>
                                    <List.Item as="li">{e}</List.Item>
                                </List>
                            )
                        })}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}
